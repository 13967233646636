// Home.js
import React, { useEffect, useState } from 'react';
import '../CSS/master.css';
import Logo from '../images/serbius_white.png';
import axios from 'axios';
import SearchLeads from './SearchLeads';
import CryptoJS from 'crypto-js';
import { TailSpin } from 'react-loader-spinner'



const Home = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true)
  const [leadsLoaded, setLeadsLoaded] = useState(false)


  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    const startTime = performance.now();
    try {
      const token = localStorage.getItem('token');
      const data = '';
  
      const secretKey = process.env.REACT_APP_SECRET_KEY;
      const hmac = CryptoJS.HmacSHA256(data, secretKey);
      const hmacDigest = hmac.toString(CryptoJS.enc.Hex);
  
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-HMAC-Signature': hmacDigest,
        },
        withCredentials: true,
      });
  
      setLeads(response.data);
      setLoading(false);
      setLeadsLoaded(true);
      const endTime = performance.now();
    } catch (error) {
      console.error('Error:', error.message || 'Failed to connect to server');
    }
  };
  


 


  const logout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/logout`, {}, { withCredentials: true });
      console.log('Logged out successfully');
      localStorage.removeItem('token');
      window.location.href = '/';
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <div className="container bg-gradient-to-br from-slate-950 to-purple-950 flex flex-col min-w-full min-h-screen justify-center items-center place-content-center">
      <img src={Logo} alt='Serbius Logo' className="w-1/4 my-5" />
      <div className="container flex flex-col justify-center bg-white p-5 rounded-2xl text-center md:w-3/4  lg:w-1/2 sm:w-11/12 shadow-lg shadow-slate-900">
        {loading && (<div className='container flex min-w-full justify-center'>
          <TailSpin
            visible={true}
            height="60"
            width="60"
            color="#7C3B9B"
            ariaLabel="tail-spin-loading"
            radius="1"
            strokeWidth={"5px"}
            wrapperStyle={{}}
            wrapperClass=""
            />
          </div>)}

        {leadsLoaded && (
                  <SearchLeads leads={leads} />
        )}
        
      </div>
      <div className="container  flex justify-start md:w-3/4  lg:w-1/2 sm:w-11/12 my-5">
        <p className="text-white">© 2022-2024 Serbius Australia. V1.1.4</p>
      </div>
    </div>
  );
}

export default Home
