import * as React from "react";
import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import SignInForm from "./components/SignInForm";
import SignUpForm from "./components/SignUpForm";
import EmailConfirmation from "./components/EmailConfirm";



import './CSS/master.css'
import './CSS/tailwind.css'


function App() {
  

  

  return (
      <Routes>
        <Route path="/" Component={Home}/> 
        




      </Routes>
  );
}

export default App;